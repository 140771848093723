@import "./fonts.css";

body,
#root {
  min-height: 100vh;
  margin: 0;
}

.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

[dir="rtl"] .MuiSelect-iconFilled {
  right: auto;
  left: 7px;
}

[dir="rtl"] .MuiSelect-filled.MuiSelect-filled {
  padding-right: 12px;
}

[dir="ltr"] .MuiSelect-iconFilled {
  left: auto;
  right: 7px;
}

[dir="ltr"] .MuiSelect-filled.MuiSelect-filled {
  padding-left: 12px;
}

/***************************/
/***************************/
/***************************/
/******* DATA GRIDS ********/
/***************************/
/***************************/
/***************************/

.data-grid-container .data-grid tr .cell.index-cell {
  min-width: 50px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.45);
}

.data-grid-container .data-grid tr .cell {
  min-width: 200px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(0, 0, 0, 0.45);
  text-align: center;
  vertical-align: middle;
}
.data-grid-container .data-grid tr .cell.read-only {
  background-color: rgba(0, 0, 0, 0.05);
  color: inherit;
}

.data-grid-container .data-grid tr .cell > input {
  height: 100%;
  width: 100%;
  text-align: center;
}

/***************************/
/***************************/
/********* IFRAMES *********/
/***************************/
/***************************/

.MuiDialog-paperScrollPaper {
  height: 100%;
}

.frontdesk-iframe {
  border: none;
}

.MuiDialogContent-root.MuiDialogContent-dividers {
  padding: 0;
}

.iframe-embeded #root > header,
.iframe-embeded #root > nav,
.iframe-embeded #root > main > div:first-child,
.iframe-embeded #root main .resource-toolbar {
  display: none;
}

.iframe-embeded #root > main {
  margin: 0;
  width: 100%;
}

/***************************/
/***************************/
/******** MEDICAL *********/
/***************************/
/***************************/
.prescription-body {
  width: 100%;
  text-align: center;
  direction: ltr;
  padding: 1rem;
  font-family: inherit;
  border: 1px solid #dedede;
  background: #fff;
}
@media only screen {
  .prescription-body {
    font-size: 1.2rem;
    min-height: 500px;
  }
}
/***************************/
/***************************/
/******** FIXES *********/
/***************************/
/***************************/
.MuiAutocomplete-popper {
  min-width: 300px !important;
}

/***************************/
/***************************/
/******** PRINTING *********/
/***************************/
/***************************/

.print-only {
  display: none;
}

.page-break-always {
  display: none;
}

@media only print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    /* -webkit-print-color-adjust: exact; */
    /* font-weight: bold !important; */
    /* font-size: 50px !important; */
    color: #000 !important;
  }
  body {
    border: 1px solid transparent;
  }

  td,
  th {
    padding: 5px !important;
    color: #000 !important;
  }

  .print-content-table td,
  .print-content-table th {
    border: 1px solid #000 !important;
  }

  /* .print-content-table th {
    font-weight: bold !important;
    padding: 10px !important;
  } */

  .print-only {
    display: initial;
  }

  .page-break-always {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }

  .MuiPaper-elevation4 {
    box-shadow: none !important;
  }

  #root > header,
  #root > nav {
    display: none;
  }

  #root > main {
    width: 100%;
    margin: 0;
  }

  .no-print {
    display: none !important;
  }
  .prescription-body {
    border: none;
    padding-top: 0;
  }
  .two-columns .prescription-body {
    column-width: 6cm;
    min-height: 12cm;
    column-gap: 0.8cm;
    column-rule: 1px dotted rgba(0, 0, 0, 0.25);
  }

  [data-slate-length="0"] {
    display: none;
  }
}

.report-grid-borders .MuiTableCell-root {
  border: 1px solid rgb(177, 177, 177);
}
