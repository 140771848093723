/* @font-face {
  font-family: "Droid Arabic Naskh";
  src: url("../fonts/DroidNaskh-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Droid Arabic Naskh";
  src: url("../fonts/DroidNaskh-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
} */

@font-face {
  font-family: "Tajawal";
  src: url("../fonts/Tajawal-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Tajawal";
  src: url("../fonts/Tajawal-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
